import { IRoadLoadResponse } from "@modules/roadLoad/model";
import { STATUSROADLOAD } from "@utils/enum";

export const mockRoadLoadAll: IRoadLoadResponse = {
  total: 7,
  content: [
    // 1) Exemplo de SCHEDULED
    {
      tableMetadata: {
        // ===== Campos obrigatórios =====
        Cliente: "Auto Posto XPTO",
        Produto: "GASOLINA COMUM",
        Quantidade: 5000,
        Data_solicitada_de_entrega: "2025-04-03T04:00:00.000Z",
        Data_de_entrega: "2025-04-03T04:00:00.000Z",
        Hora_de_entrega: "04:00:00",
        Filial: "C003",
        NomeFilial: "PORTO VELHO",
        Agendamento: "2025-04-03",

        // ===== Campos opcionais =====
        CNPJ: "10976424000735",
        Id: "7d295a19-b8e1-4d88-86ec-dbf6fcb80002",
        Ref: "030827",
        IdMotorista: "5bfe2aff-cd9c-418a-83c5-795fc47bf7b8",
        Motorista: "Andre Moraes",
        Qtde_ordens_de_venda: 1,
        Data_e_hora_agendamento: "2025-04-03T17:00:00.000Z",
        Hora_agendendamento: "13:00:00",
        Data_agendamento: "2025-04-03",
        Timezone: "America/Manaus",
        Status_Agendamento: "Agendado",
        Status_Programacao: "PENDING",
        Documentacao_Transporte_Pendente: false,
        Motivo_cancelamento: "",
        Descricao_cancelamento: "",
        Tipo_de_frete: "FOB",
        Placa: "AAT8566", // valor da lista de placas
        IdTransportadora: "abc123",
        Transportadora: "Transportadora XPTO",

        // ===== Items (sem Data_de_entrega) =====
        Items: [
          {
            CodCliente: "168",
            Cliente: "AUTO POSTO CALAMA LTDA",
            CNPJ: "10976424000735",
            Produto: "GASOLINA C COMUM",
            CodProduto: "10076",
            Quantidade: 5000,
            Ordem_de_venda: "1077918",
            Status: "Liberado",
            Tipo_de_frete: "FOB",
          },
        ],

        // ===== Transporte =====
        Transporte: [
          {
            Numero: 1,
            Volume: 3000,
            Tipo: "allocated",
            Ordem_de_venda: "1077918",
          },
          {
            Numero: 2,
            Volume: 2000,
            Tipo: "allocated",
            Ordem_de_venda: "1077918",
          },
        ],
      },
      status: STATUSROADLOAD.SCHEDULED,
      schedulable: "true",
      reschedulable: true,
      cancelable: true,
      numberOrder: "030827",
      sortKey: 1,
    },

    // 2) Exemplo de FINISHED
    {
      tableMetadata: {
        Cliente: "Auto Posto Y",
        Produto: "ETANOL HIDRATADO",
        Quantidade: 10000,
        Data_solicitada_de_entrega: "2025-04-04T04:00:00.000Z",
        Data_de_entrega: "2025-04-04T04:00:00.000Z",
        Hora_de_entrega: "04:00:00",
        Filial: "C003",
        NomeFilial: "PORTO VELHO",
        Agendamento: "2025-04-04",

        CNPJ: "10976424000654",
        Id: "9c69490a-0105-416f-93b1-14f1f827970c",
        Ref: "030761",
        IdMotorista: "5bfe2aff-cd9c-418a-83c5-795fc47bf7b8",
        Motorista: "Andre Moraes",
        Qtde_ordens_de_venda: 2,
        Data_e_hora_agendamento: "2025-04-04T08:00:00.000Z",
        Hora_agendendamento: "04:00:00",
        Data_agendamento: "2025-04-04",
        Timezone: "America/Manaus",
        Status_Agendamento: "Finalizado",
        Status_Programacao: "PENDING",
        Documentacao_Transporte_Pendente: false,
        Motivo_cancelamento: "",
        Descricao_cancelamento: "",
        Tipo_de_frete: "FOB",
        Placa: "AAM8080", // outra placa
        IdTransportadora: "transp-001",
        Transportadora: "Transportadora XYZ",

        Items: [
          {
            CodCliente: "175",
            Cliente: "AMAZON COMBUSTIVEIS PARA VEICU",
            CNPJ: "10988014000623",
            Produto: "ETANOL HIDRATADO COMBUSTIVEL",
            CodProduto: "10059",
            Quantidade: 5000,
            Ordem_de_venda: "1083922",
            Status: "Faturado",
            Tipo_de_frete: "FOB",
          },
          {
            CodCliente: "167",
            Cliente: "AUTO POSTO CALAMA LTDA",
            CNPJ: "10976424000654",
            Produto: "GASOLINA C COMUM",
            CodProduto: "10076",
            Quantidade: 5000,
            Ordem_de_venda: "1084157",
            Status: "Faturado",
            Tipo_de_frete: "FOB",
          },
        ],
        Transporte: [
          {
            Numero: 1,
            Volume: 5000,
            Tipo: "allocated",
            Ordem_de_venda: "1083922",
          },
          {
            Numero: 2,
            Volume: 5000,
            Tipo: "allocated",
            Ordem_de_venda: "1084157",
          },
        ],
      },
      status: STATUSROADLOAD.FINISHED,
      schedulable: "false",
      reschedulable: false,
      cancelable: false,
      numberOrder: "030761",
      sortKey: 2,
    },

    // 3) Exemplo de CANCELED
    {
      tableMetadata: {
        Cliente: "Auto Posto Z",
        Produto: "OLEO DIESEL B S10",
        Quantidade: 5000,
        Data_solicitada_de_entrega: "2025-04-05T04:00:00.000Z",
        Data_de_entrega: "2025-04-05T04:00:00.000Z",
        Hora_de_entrega: "04:00:00",
        Filial: "C009",
        NomeFilial: "BELEM",
        Agendamento: "2025-04-05",

        CNPJ: "10976424000140",
        Id: "ee3dfe9e-571e-4fcc-b72a-9b9aa913e103",
        Ref: "029922",
        IdMotorista: "a86e36f8-4e4c-4874-848e-3dade02b6649",
        Motorista: "Jucivan Gomes",
        Qtde_ordens_de_venda: 0,
        Data_e_hora_agendamento: "2025-04-05T14:00:00.000Z",
        Hora_agendendamento: "10:00:00",
        Data_agendamento: "2025-04-05",
        Timezone: "America/Belem",
        Status_Agendamento: "Cancelado",
        Status_Programacao: "PENDING",
        Documentacao_Transporte_Pendente: false,
        Motivo_cancelamento: "Desistência",
        Descricao_cancelamento: "Cancelado manualmente pelo usuário",
        Tipo_de_frete: "FOB",
        Placa: "ABC1234",
        IdTransportadora: "cancel-001",
        Transportadora: "Transportadora CANCEL",

        Items: [],
        Transporte: [],
      },
      status: STATUSROADLOAD.CANCELED,
      schedulable: "false",
      reschedulable: false,
      cancelable: false,
      numberOrder: "029922",
      sortKey: 3,
    },

    // 4) Exemplo de WAITING_SCHEDULE (não existe no enum, mas usado no seu código)
    {
      tableMetadata: {
        Cliente: "AUTO POSTO CALAMA LTDA",
        Produto: "GASOLINA C ADITIVADA",
        Quantidade: 5000,
        Data_solicitada_de_entrega: "2025-04-03T04:00:00.000Z",
        Data_de_entrega: "2025-04-03T04:00:00.000Z",
        Hora_de_entrega: "04:00:00",
        Filial: "C003",
        NomeFilial: "PORTO VELHO",
        Agendamento: "2025-04-03",

        CNPJ: "10976424000654",
        Id: "11111111-2222-3333-4444-555555555555",
        Ref: "REF_WS_01",
        IdMotorista: "some-driver-id",
        Motorista: "Paulo Silva",
        Qtde_ordens_de_venda: 1,
        Data_e_hora_agendamento: "2025-04-03T17:00:00.000Z",
        Hora_agendendamento: "13:00:00",
        Data_agendamento: "2025-04-03",
        Timezone: "America/Manaus",
        Status_Agendamento: "Aguardando",
        Status_Programacao: "PENDING",
        Documentacao_Transporte_Pendente: false,
        Motivo_cancelamento: "",
        Descricao_cancelamento: "",
        Tipo_de_frete: "FOB",
        Placa: "AAN8383",
        IdTransportadora: "wait-001",
        Transportadora: "Transportadora WAIT",

        Items: [
          {
            CodCliente: "167",
            Cliente: "AUTO POSTO CALAMA LTDA",
            CNPJ: "10976424000654",
            Produto: "GASOLINA C ADITIVADA",
            CodProduto: "10122",
            Quantidade: 5000,
            Ordem_de_venda: "1082737",
            Status: "Liberado",
            Tipo_de_frete: "FOB",
          },
        ],
        Transporte: [
          {
            Numero: 1,
            Volume: 5000,
            Tipo: "allocated",
            Ordem_de_venda: "1082737",
          },
        ],
      },
      status: "WAITING_SCHEDULE", // mesmo que "Nao Agendado"
      schedulable: "true",
      reschedulable: true,
      cancelable: true,
      numberOrder: "1082737",
      sortKey: 4,
    },

    // 5) Exemplo de BLOCKED
    {
      tableMetadata: {
        Cliente: "AMAZON COMBUSTIVEIS PARA VEICU",
        Produto: "OLEO DIESEL B S10",
        Quantidade: 5000,
        Data_solicitada_de_entrega: "2025-04-04T04:00:00.000Z",
        Data_de_entrega: "2025-04-04T04:00:00.000Z",
        Hora_de_entrega: "04:00:00",
        Filial: "C003",
        NomeFilial: "PORTO VELHO",
        Agendamento: "2025-04-04",

        CNPJ: "10988014000623",
        Id: "22222222-3333-4444-5555-666666666666",
        Ref: "REF_BL_01",
        IdMotorista: "some-driver-blocked",
        Motorista: "José Bloqueado",
        Qtde_ordens_de_venda: 1,
        Data_e_hora_agendamento: "2025-04-04T17:00:00.000Z",
        Hora_agendendamento: "13:00:00",
        Data_agendamento: "2025-04-04",
        Timezone: "America/Manaus",
        Status_Agendamento: "Bloqueado",
        Status_Programacao: "PENDING",
        Documentacao_Transporte_Pendente: false,
        Motivo_cancelamento: "",
        Descricao_cancelamento: "",
        Tipo_de_frete: "FOB",
        Placa: "AAT6B18",
        IdTransportadora: "block-123",
        Transportadora: "Transportadora BLOCK",

        Items: [
          {
            CodCliente: "175",
            Cliente: "AMAZON COMBUSTIVEIS PARA VEICU",
            CNPJ: "10988014000623",
            Produto: "OLEO DIESEL B S10",
            CodProduto: "10474",
            Quantidade: 5000,
            Ordem_de_venda: "1089325",
            Status: "Bloqueado",
            Tipo_de_frete: "FOB",
          },
        ],
        Transporte: [
          {
            Numero: 1,
            Volume: 5000,
            Tipo: "blocked",
            Ordem_de_venda: "1089325",
          },
        ],
      },
      status: STATUSROADLOAD.BLOCKED,
      schedulable: "false",
      reschedulable: false,
      cancelable: false,
      numberOrder: "1089325",
      sortKey: 5,
    },

    // 6) Outro exemplo de FINISHED (para ter mais de um item no mock)
    {
      tableMetadata: {
        Cliente: "POSTO TORQUATO COMERCIO DE DERIV",
        Produto: "GASOLINA C COMUM",
        Quantidade: 10000,
        Data_solicitada_de_entrega: "2025-04-01T04:00:00.000Z",
        Data_de_entrega: "2025-04-01T04:00:00.000Z",
        Hora_de_entrega: "04:00:00",
        Filial: "C001",
        NomeFilial: "MANAUS",
        Agendamento: "2025-04-01",

        CNPJ: "26928710000170",
        Id: "80b5756b-4007-4d33-9d8b-489b9f3be57f",
        Ref: "030468",
        IdMotorista: "2a2372b4-688b-41a1-9632-f5c410f1f633",
        Motorista: "Manoel Figueiredo",
        Qtde_ordens_de_venda: 2,
        Data_e_hora_agendamento: "2025-04-01T09:00:00.000Z",
        Hora_agendendamento: "05:00:00",
        Data_agendamento: "2025-04-01",
        Timezone: "America/Manaus",
        Status_Agendamento: "Finalizado",
        Status_Programacao: "PENDING",
        Documentacao_Transporte_Pendente: false,
        Motivo_cancelamento: "",
        Descricao_cancelamento: "",
        Tipo_de_frete: "FOB",
        Placa: "ABCACB",
        IdTransportadora: "transp-002",
        Transportadora: "Transp & Cia",

        Items: [
          {
            CodCliente: "563",
            Cliente: "POSTO TORQUATO COMERCIO DE DERIV",
            CNPJ: "26928710000170",
            Produto: "ETANOL HIDRATADO COMBUSTIVEL",
            CodProduto: "10059",
            Quantidade: 5000,
            Ordem_de_venda: "1088595",
            Status: "Faturado",
            Tipo_de_frete: "FOB",
          },
          {
            CodCliente: "563",
            Cliente: "POSTO TORQUATO COMERCIO DE DERIV",
            CNPJ: "26928710000170",
            Produto: "GASOLINA C COMUM",
            CodProduto: "10076",
            Quantidade: 5000,
            Ordem_de_venda: "1089128",
            Status: "Faturado",
            Tipo_de_frete: "FOB",
          },
        ],
        Transporte: [
          {
            Numero: 1,
            Volume: 5000,
            Tipo: "allocated",
            Ordem_de_venda: "1088595",
          },
          {
            Numero: 2,
            Volume: 5000,
            Tipo: "allocated",
            Ordem_de_venda: "1089128",
          },
        ],
      },
      status: STATUSROADLOAD.FINISHED,
      schedulable: "false",
      reschedulable: false,
      cancelable: false,
      numberOrder: "030468",
      sortKey: 6,
    },

    // 7) Outro exemplo de SCHEDULED
    {
      tableMetadata: {
        Cliente: "AUTO POSTO BRASIL LIMITADA",
        Produto: "GASOLINA C ADITIVADA",
        Quantidade: 5000,
        Data_solicitada_de_entrega: "2025-04-10T04:00:00.000Z",
        Data_de_entrega: "2025-04-10T04:00:00.000Z",
        Hora_de_entrega: "04:00:00",
        Filial: "C001",
        NomeFilial: "MANAUS",
        Agendamento: "2025-04-10",

        CNPJ: "04819363000104",
        Id: "abcd1234-efgh-5678-ijkl-9090909090",
        Ref: "031000",
        IdMotorista: "driver-9999",
        Motorista: "Fulano de Tal",
        Qtde_ordens_de_venda: 1,
        Data_e_hora_agendamento: "2025-04-10T09:00:00.000Z",
        Hora_agendendamento: "05:00:00",
        Data_agendamento: "2025-04-10",
        Timezone: "America/Manaus",
        Status_Agendamento: "Agendado",
        Status_Programacao: "PENDING",
        Documentacao_Transporte_Pendente: false,
        Motivo_cancelamento: "",
        Descricao_cancelamento: "",
        Tipo_de_frete: "FOB",
        Placa: "AAH7006",
        IdTransportadora: "transp-9999",
        Transportadora: "Transportadora Manaus",

        Items: [
          {
            CodCliente: "11699",
            Cliente: "AUTO POSTO BRASIL LIMITADA",
            CNPJ: "04819363000104",
            Produto: "GASOLINA C ADITIVADA",
            CodProduto: "10122",
            Quantidade: 5000,
            Ordem_de_venda: "240596",
            Status: "Liberado",
            Tipo_de_frete: "FOB",
          },
        ],
        Transporte: [
          {
            Numero: 1,
            Volume: 5000,
            Tipo: "allocated",
            Ordem_de_venda: "240596",
          },
        ],
      },
      status: STATUSROADLOAD.SCHEDULED,
      schedulable: "true",
      reschedulable: true,
      cancelable: true,
      numberOrder: "031000",
      sortKey: 7,
    },
  ],
};
