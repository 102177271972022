import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

interface ModalDocumentsPendingProps {
  open: boolean;
  onClose: () => void;
  row: any;
  vehicleWarnings: string[];
  driverWarnings: string[];
}

const ModalDocumentsPending: React.FC<ModalDocumentsPendingProps> = ({
  open,
  onClose,
  row,
  vehicleWarnings,
  driverWarnings,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          background: "#fff",
          width: { xs: "90%", sm: "800px" },
          margin: { xs: "20px auto", sm: "100px auto" },
          padding: { xs: 2, sm: 4 },
          borderRadius: 2,
          maxHeight: { xs: "90vh", sm: "auto" },
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 4,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              A placa informada possui licenças vencidas
            </Typography>

            {vehicleWarnings.length ? (
              <Box
                component="ul"
                sx={{
                  marginLeft: { xs: 2, sm: "20px" },
                  pl: { xs: 1, sm: 2 },
                }}
              >
                {vehicleWarnings.map((warn, i) => (
                  <Box
                    component="li"
                    key={i}
                    sx={{
                      color: "#CF0000",
                      mb: "4px",
                      fontSize: { xs: "1.2rem", sm: "1rem" },
                    }}
                  >
                    {warn}
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Nenhum documento pendente para esta placa.
              </Typography>
            )}
          </Box>

          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              O motorista informado possui licenças vencidas
            </Typography>

            {driverWarnings.length ? (
              <Box
                component="ul"
                sx={{
                  marginLeft: { xs: 2, sm: "20px" },
                  pl: { xs: 1, sm: 2 },
                }}
              >
                {driverWarnings.map((warn, i) => (
                  <Box
                    component="li"
                    key={i}
                    sx={{
                      color: "#CF0000",
                      mb: "4px",
                      fontSize: { xs: "1.2rem", sm: "1rem" },
                    }}
                  >
                    {warn}
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Nenhum documento pendente para o motorista.
              </Typography>
            )}
          </Box>
        </Box>

        <Typography
          variant="h6"
          sx={{
            mt: 4,
            fontSize: { xs: "1.2rem", sm: "1rem", xl: "1.4rem" },
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Para prosseguir com este carregamento, é necessário atualizar as
          documentações informadas!
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1rem", xl: "1.4rem" },
              textAlign: "center",
            }}
          >
            1. O motorista deve comparecer ou entrar em contato com o Drive In
            da Base, com os documentos e solicitar atualização.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mt: 1,
              fontSize: { xs: "1.2rem", sm: "1rem", xl: "1.4rem" },
              textAlign: "center",
            }}
          >
            2. Após a atualização, o carregamento poderá prosseguir conforme
            programado.
          </Typography>
        </Box>

        <Box textAlign="center" sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="error"
            onClick={onClose}
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", xl: "1.4rem" },
              width: { xs: "100%", sm: "auto" },
            }}
          >
            Ciente
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalDocumentsPending;
