import { Box, BoxProps, SxProps, Theme } from "@mui/system";
import { generateFilter } from "colorize-filter";
import React from "react";

export type IconType = "badge" | "logo-atem" | "trash" | "alert";

export interface StyledIconProps extends BoxProps<"img"> {
  iconType: IconType;
  iconColor?: string;
}

const StyledIcon: React.FC<StyledIconProps> = ({
  iconType,
  iconColor,
  ...props
}) => {
  const sxProps: SxProps<Theme> = {
    width: "1.8rem",
    height: "1.8rem",
  };

  const selectIcon = () => {
    switch (iconType) {
      case "badge": {
        return {
          src: "/icons/appBar/badge.svg",
          alt: "badge",
        };
      }
      case "logo-atem": {
        return {
          src: "/icons/sideBar/logo-atem.svg",
          alt: "logo-atem",
        };
      }
      case "trash": {
        return {
          src: "/icons/trash.svg",
          alt: "lixeira",
        };
      }
      case "alert": {
        return {
          src: "/icons/table/alert.svg",
          alt: "alert",
        };
      }
    }
  };

  const icon = selectIcon();

  return (
    <Box
      component="img"
      {...props}
      {...icon}
      sx={{
        ...sxProps,
        ...props.sx,
        filter: iconColor ? generateFilter(iconColor) : undefined,
      }}
    />
  );
};

export { StyledIcon };
