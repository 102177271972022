import React from "react";
import { Paper, Typography, Grid } from "@material-ui/core";
import useStyles from "./styles";
import { AlertRedIcon, AlertYellowIcon } from "@components/Icons";

interface Product {
  id: string;
  name: string;
  volume: number;
  compartments: number[];
  Status?: string;
}

interface ProductListProps {
  products: Product[];
  onProductClick: (productId: string) => void;
}

const ProductList: React.FC<ProductListProps> = ({
  products,
  onProductClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {products.map((product) => {
        const isCompartmentOccupied = product.name
          .toLowerCase()
          .includes("ocupado");
        const code = product.id;

        const isDisabled = product?.Status === "Bloqueado";
        return (
          <Paper
            key={product.id}
            className={isDisabled ? classes.cardDisabled : classes.card}
            elevation={0}
            onClick={() => {
              if (!isDisabled) {
                onProductClick(product.id);
              }
            }}
            style={{ display: "block" }}
          >
            <Grid container spacing={1} className={classes.leftSide}>
              {!isCompartmentOccupied && (
                <Grid item xs={3}>
                  <Typography className={classes.primaryText}>OV</Typography>
                </Grid>
              )}
              <Grid item xs={isCompartmentOccupied ? 12 : 9}>
                <Typography
                  className={
                    isCompartmentOccupied
                      ? classes.warningText
                      : classes.primaryText
                  }
                >
                  {product.name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.leftSide}>
              <Grid item xs={3}>
                <Typography className={classes.secondaryText}>
                  {code}
                </Typography>
              </Grid>
              <Grid
                item
                xs={9}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography className={classes.secondaryText}>
                  {product.volume.toLocaleString()} litros
                </Typography>
                {product.compartments.length > 0 ? (
                  <div className={classes.compartmentsContainer}>
                    {product.compartments.map((compId) => (
                      <div
                        key={compId}
                        className={
                          isCompartmentOccupied
                            ? classes.compartmentOccupiedBadge
                            : classes.compartmentBadge
                        }
                      >
                        {String(compId).padStart(2, "0")}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={classes.compartmentsContainer}>
                    {isDisabled ? (
                      <AlertRedIcon style={{ height: "24px", width: "25px" }} />
                    ) : (
                      <AlertYellowIcon
                        style={{ height: "24px", width: "25px" }}
                      />
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </div>
  );
};

export default ProductList;
