import React from "react";
import FloatingMenuList from "@components/FloatingMenuListMobile";
import { Checkbox, Grid, Typography, useTheme } from "@material-ui/core";
import useStyles from "./styles";
import { useMediaQuery } from "@mui/material";
import { ModalGeneral } from "@components/ModalGeneral/ModalGeneral";

interface Product {
  id: string;
  name: string;
  volume: number;
  compartments: number[];
}

interface ProductCompartmentsModalProps {
  product: Product;
  availableCompartments: {
    id: number;
    label: string;
    volume: number;
  }[];
  onClose: () => void;
  onUpdateCompartments: (newCompartments: number[]) => void;
}

const ProductCompartmentsModal: React.FC<ProductCompartmentsModalProps> = ({
  product,
  availableCompartments,
  onClose,
  onUpdateCompartments,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const handleToggleCompartment = (id: number) => {
    const isSelected = product.compartments.includes(id);

    // NOTE: (1) Se o usuário está DESMARCANDO um compartimento,
    /*basta removê-lo da lista. Assim a soma diminui e podemos
      eventualmente permitir escolher outro.*/
    if (isSelected) {
      const newCompartments = product.compartments.filter(
        (compId) => compId !== id
      );
      onUpdateCompartments(newCompartments);
      return;
    }

    // NOTE: (2) Se o usuário está MARCANDO um compartimento novo:
    // Primeiro, somamos as capacidades já selecionadas.
    const sumOfSelectedCompartments = product.compartments.reduce(
      (acc, compId) => {
        const c = availableCompartments.find((cc) => cc.id === compId);
        return acc + (c ? c.volume : 0);
      },
      0
    );

    //NOTE:
    /* Se a soma atual já for >= ao volume do produto,
       então não precisamos (nem podemos) selecionar outro.*/
    if (sumOfSelectedCompartments >= product.volume) {
      alert(
        "Você já tem capacidade suficiente para este produto. Desmarque algum compartimento se quiser usar outro."
      );
      return;
    }

    //NOTE: Agora pegamos o compartimento que o usuário está clicando.
    const compartmentToAdd = availableCompartments.find((c) => c.id === id);
    if (!compartmentToAdd) return;

    //NOTE:
    /* Podemos simplesmente adicionar esse compartimento.
       Se com ele a soma ultrapassar O VOLUME DO PRODUTO, tudo bem:
       a gente só bloqueia a partir da PRÓXIMA tentativa.*/
    const newCompartments = [...product.compartments, id];
    onUpdateCompartments(newCompartments);
  };

  interface RenderCompartimentsProps {
    title?: string;
  }

  const RenderCompartiments: React.FC<RenderCompartimentsProps> = ({
    title,
  }) => {
    return (
      <>
        <Grid className={classes.centeredGrid}>
          {title && (
            <Typography variant="body1" className={classes.boldText}>
              {title}
            </Typography>
          )}

          <Typography
            className={classes.productInfoText}
            style={{
              paddingTop: isMobile ? "1rem" : "2rem",
              paddingBottom: isMobile ? "1rem" : "2rem",
            }}
          >
            {`${product.name} - ${product.volume.toLocaleString()} litros`}
          </Typography>
        </Grid>

        <div
          className={classes.container}
          style={{
            paddingRight: isMobile ? "1rem" : "2rem",
            paddingLeft: isMobile ? "1rem" : "2rem",
          }}
        >
          {availableCompartments.map((compartment, idx) => (
            <div key={compartment.id} className={classes.compartmentItem}>
              <label className={classes.label}>
                <Checkbox
                  checked={product.compartments.includes(compartment.id)}
                  onChange={() => handleToggleCompartment(compartment.id)}
                  className={classes.checkbox}
                  style={{ color: theme.palette.primary.main }}
                />
                {compartment.label}
              </label>
              <span className={classes.volumeText}>
                {compartment.volume.toLocaleString()} L
              </span>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      {isMobile ? (
        <FloatingMenuList handleClose={onClose}>
          <RenderCompartiments title="Selecione o compartimento onde o produto será alocado" />
        </FloatingMenuList>
      ) : (
        <ModalGeneral
          open={!!product}
          closeModal={onClose}
          titleHeader="Selecione o compartimento onde o produto será alocado"
          width={"500px"}
          height={"400px"}
          hasDialogActions={false}
          resetPadding={true}
        >
          <RenderCompartiments />
        </ModalGeneral>
      )}
    </>
  );
};

export default ProductCompartmentsModal;
