import React from "react";
import { useTheme } from "@material-ui/core";
import { Typography, TypographyProps } from "@mui/material";
import { capitalizeFirstLetter } from "@utils/index";

const TypographyTitle: React.FC<TypographyProps> = ({ children, ...props }) => {
  const theme = useTheme();
  const { sx, ...rest } = props;

  return (
    <Typography
      variant="h5"
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 17, sm: 16, md: 18, lg: 22, xl: 24 },
        fontWeight: 700,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const TypographySubtitle: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  const { sx, ...rest } = props;
  return (
    <Typography
      variant="h6"
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 14, sm: 15, md: 16, lg: 18, xl: 20 },
        fontWeight: 700,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const TypographyCaption: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  const { sx, ...rest } = props;

  return (
    <Typography
      variant="caption"
      sx={{
        textTransform: "none",
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 11, sm: 12, md: 14, lg: 14, xl: 16 },
        fontWeight: 400,
        ...sx,
      }}
      {...rest}
    >
      {typeof children === "string"
        ? capitalizeFirstLetter(children)
        : children}
    </Typography>
  );
};

const CardTitleTypography: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  const { sx, ...rest } = props;
  return (
    <Typography
      variant={"body1"}
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 14, md: 15, lg: 16, xl: 16 },
        fontWeight: 700,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const CardHeadTypography: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  const { sx, ...rest } = props;
  return (
    <Typography
      variant={"body1"}
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 14, md: 12, lg: 13, xl: 15 },
        fontWeight: 700,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const CardBodyTypography: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  const { sx, ...rest } = props;
  return (
    <Typography
      variant={"body1"}
      sx={{
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 12, md: 12, lg: 12, xl: 13 },
        fontWeight: 500,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const TableHeadTypography: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  const { sx, ...rest } = props;
  return (
    <Typography
      variant={"body1"}
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 14, md: 14, lg: 14, xl: 16 },
        fontWeight: 700,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const TableBodyTypography: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  const { sx, ...rest } = props;
  return (
    <Typography
      variant={"body1"}
      sx={{
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 12, md: 12, lg: 14, xl: 16 },
        fontWeight: 500,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const TableTabsTypography: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  const { sx, ...rest } = props;
  return (
    <Typography
      variant={"body1"}
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 14, md: 14, lg: 16, xl: 18 },
        fontWeight: 700,
        textTransform: "capitalize",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export {
  TypographyCaption,
  TypographySubtitle,
  TypographyTitle,
  TableHeadTypography,
  TableTabsTypography,
  TableBodyTypography,
  CardHeadTypography,
  CardBodyTypography,
  CardTitleTypography,
};
