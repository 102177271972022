import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { IGetVehicleResponse } from "@modules/transport/dtos/IGetVehicleResponse";
import { ICreateTransportBody } from "@modules/transport/models/ICreateTransportBody";
import { IProgrammingTransportService } from "@modules/transport/models/IProgrammingTransportService";
import { Types } from "@ioc/types";
import { IScheduledLoad } from "@pages/User/RoadLoad/model";
import AppError from "@utils/AppError";
import { useSnackbar } from "notistack";
import { IGetDriverResponse } from "@modules/transport/dtos/IGetDriverResponse";

export interface Compartment {
  capacity: number;
  index: number;
  type: string;
  isChecked: boolean;
}

export interface Product {
  id: string;
  name: string;
  volume: number;
  compartments: number[];
  Status: string;
}

export interface IExtendedTransportBody extends ICreateTransportBody {
  truckCompartmentOccupied: boolean;
}

interface IProgrammingTransportContext {
  getVehicleResponse: IGetVehicleResponse[] | null;
  setGetVehicleResponse: React.Dispatch<
    React.SetStateAction<IGetVehicleResponse[] | null>
  >;

  createTransportBody: IExtendedTransportBody;
  setCreateTransportBody: React.Dispatch<
    React.SetStateAction<IExtendedTransportBody>
  >;

  updateTransportBody: (items: any[]) => void;
  deleteTransportId: string;
  setDeleteTransportId: React.Dispatch<React.SetStateAction<string>>;

  getVehicle: (id: string, plate: string) => Promise<IGetVehicleResponse[]>;
  fetchCompartments: (id: string, plate: string) => Promise<Compartment[]>;
  createTransport: (id: string, body: ICreateTransportBody) => Promise<void>;
  deleteTransport: (id: string) => Promise<void>;
  getDriver: (driverId: string) => Promise<IGetDriverResponse>;
  occupiedCompartments: Compartment[];
  setOccupiedCompartments: React.Dispatch<React.SetStateAction<Compartment[]>>;

  compartments: Compartment[];
  setCompartments: React.Dispatch<React.SetStateAction<Compartment[]>>;

  rowSelected: IScheduledLoad | null | undefined;
  setRowSelected: React.Dispatch<
    React.SetStateAction<IScheduledLoad | null | undefined>
  >;
  resetContext: () => void;
  setAllAllocatedProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  allAllocatedProducts: Product[];
}

const ProgrammingTransportContext = createContext<IProgrammingTransportContext>(
  {} as IProgrammingTransportContext
);

const ProgrammingTransportProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const programmingTransportService = iocContext.serviceContainer.get<
    IProgrammingTransportService
  >(Types.Transport.IProgrammingTransportService);
  const { enqueueSnackbar } = useSnackbar();

  const [getVehicleResponse, setGetVehicleResponse] = useState<
    IGetVehicleResponse[] | null
  >(null);

  const [createTransportBody, setCreateTransportBody] = useState<
    IExtendedTransportBody
  >({
    plate: "",
    items: [],
    truckCompartmentOccupied: false,
  });

  const [deleteTransportId, setDeleteTransportId] = useState<string>("");
  const [compartments, setCompartments] = useState<Compartment[]>([]);
  const [occupiedCompartments, setOccupiedCompartments] = useState<
    Compartment[]
  >([]);

  const [rowSelected, setRowSelected] = useState<
    IScheduledLoad | null | undefined
  >(() => {
    const savedRowSelected = localStorage.getItem("rowSelected");
    return savedRowSelected ? JSON.parse(savedRowSelected) : null;
  });

  const [allAllocatedProducts, setAllAllocatedProducts] = useState<Product[]>(
    () => {
      if ((createTransportBody as any).products) {
        return (createTransportBody as any).products;
      }
      if (rowSelected?.Items) {
        return rowSelected.Items.map((item) => ({
          id: item.Ordem_de_venda ?? "",
          name: item.Produto ?? "Produto Desconhecido",
          volume: item.Quantidade ?? 0,
          compartments: [],
        }));
      }
      return [];
    }
  );

  useEffect(() => {
    if (rowSelected) {
      localStorage.setItem("rowSelected", JSON.stringify(rowSelected));
    } else {
      localStorage.removeItem("rowSelected");
    }
  }, [rowSelected]);

  const resetContext = useCallback(() => {
    setCreateTransportBody({
      plate: "",
      items: [],
      truckCompartmentOccupied: false,
    });
    setDeleteTransportId("");
    setCompartments([]);
    setOccupiedCompartments([]);
    setRowSelected(null);
    setGetVehicleResponse(null);

    localStorage.removeItem("rowSelected");
  }, [
    setCreateTransportBody,
    setDeleteTransportId,
    setCompartments,
    setOccupiedCompartments,
    setRowSelected,
    setGetVehicleResponse,
  ]);

  const updateTransportBody = (items: any[]) => {
    setCreateTransportBody((prevBody) => ({
      ...prevBody,
      items,
    }));
  };

  const getVehicle = async (
    id: string | null,
    plate: string
  ): Promise<IGetVehicleResponse[]> => {
    try {
      const response = await programmingTransportService.getVehicle(id, plate);
      setGetVehicleResponse(response);
      return response;
    } catch (error) {
      console.error("Error in getVehicle:", error);
      throw error;
    }
  };

  const getDriver = async (driverId: string): Promise<IGetDriverResponse> => {
    try {
      return await programmingTransportService.getDriver(driverId);
    } catch (error) {
      console.error("Error in getDriver:", error);
      throw error;
    }
  };

  const fetchCompartments = async (
    id: string,
    plate: string
  ): Promise<Compartment[]> => {
    try {
      const response = await getVehicle(id, plate);
      const compartments = response.flatMap((vehicle) =>
        vehicle.compartments.map((comp) => ({
          capacity: comp.capacity,
          index: comp.index,
          type: comp.type,
          isChecked: false,
        }))
      );
      setOccupiedCompartments(compartments);
      return compartments;
    } catch (error) {
      throw error;
    }
  };

  const createTransport = async (
    id: string,
    body: ICreateTransportBody
  ): Promise<void> => {
    try {
      await programmingTransportService.createTransport(id, body);
      resetContext();
    } catch (error) {
      throw error;
    }
  };

  const deleteTransport = useCallback(
    async (id: string): Promise<void> => {
      try {
        await programmingTransportService.deleteTransport(id);
        enqueueSnackbar({
          message: "Programação cancelada com sucesso",
          variant: "success",
        });
        resetContext();
      } catch (error) {
        if (error instanceof AppError) {
          enqueueSnackbar(error.message, { variant: error.variant });
        }
      }
    },
    [enqueueSnackbar, resetContext, programmingTransportService]
  );

  return (
    <ProgrammingTransportContext.Provider
      value={{
        getVehicleResponse,
        setGetVehicleResponse,
        createTransportBody,
        setCreateTransportBody,
        updateTransportBody,
        deleteTransportId,
        setDeleteTransportId,
        getVehicle,
        getDriver,
        fetchCompartments,
        createTransport,
        deleteTransport,
        occupiedCompartments,
        setOccupiedCompartments,
        compartments,
        setCompartments,
        rowSelected,
        setRowSelected,
        resetContext,
        allAllocatedProducts,
        setAllAllocatedProducts,
      }}
    >
      {children}
    </ProgrammingTransportContext.Provider>
  );
};

const useProgrammingTransport = (): IProgrammingTransportContext => {
  const context = useContext(ProgrammingTransportContext);
  if (!context) {
    throw new Error(
      "useProgrammingTransport must be used within a ProgrammingTransportProvider"
    );
  }
  return context;
};

export { ProgrammingTransportProvider, useProgrammingTransport };
