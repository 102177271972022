import React, { useEffect, useState } from "react";
import { Autocomplete, Box, FormHelperText, Grid } from "@mui/material";
import SelectAutocomplete, { Option } from "@components/SelectAutocomplete";
import TextFieldCustom from "@components/TextFieldCustom";
import Modal from "@components/Modal";
import Button from "@components/Button";
import { useRoadLoad } from "../../context/RoadLoadContext";
import { IScheduledLoad } from "../../model";
import { useFlags } from "flagsmith/react";

import {
  arrayToStringJoin,
  formatDateAndHourPTBR,
  maskVehiclePlateMercoSul,
  normalizePlate,
  validatePlate,
} from "@utils/index";
import { KEY } from "@utils/enum";

import { IDrivers } from "@modules/roadLoad/model";
import { freighTypesEnum } from "@pages/User/MyOrders/models/ENUM_MY_ORDERS";
import { useProgrammingTransport } from "@context/ProgrammingTransport/ProgrammingTransportContext";
import useDialogAlert from "@hooks/useDialogAlert";
import { TextField } from "@material-ui/core";
import CircularProgress from "@components/CircularProgress";
import { TypographyTitle } from "../typography";

interface Props {
  children?: React.ReactNode;
  schedule: IScheduledLoad | null | undefined;
  hiddenIcon?: boolean;
  open: boolean;
  onClose: () => void;
}

const ModalEditDriver: React.FC<Props> = ({ schedule, open, onClose }) => {
  const { snackbar } = useDialogAlert();

  const {
    onlyDriver,
    roadLoadFilterOptions,
    handleRoadLoadMethod,
    handleFetchDriverByCNPJ,
    hasCarrier,
  } = useRoadLoad();

  const featureFlagProgrammingTransport = useFlags([
    "user_sidebar_programming_transport",
  ]).user_sidebar_programming_transport.enabled;

  const isRenderPlateFlag = featureFlagProgrammingTransport;

  const { getVehicle } = useProgrammingTransport();

  const salesOrderCNPJ = arrayToStringJoin(schedule?.salesOrder, KEY.CNPJ);

  const freight = schedule?.salesOrder?.[0]?.freight;

  const valueFreight =
    freight && freight.length > 1 ? freight : "Tipo de frete não definido";

  const isCIFSelected = freight === freighTypesEnum.CIF;

  const isFOBSelected = freight === freighTypesEnum.FOB;

  //NOTE: So é permitido alterar MOTORISTA e PLACA do tipo:CIF  usuário do tipo Transportadora (carrier)
  const hasCarrierAndHasCIF = isCIFSelected && hasCarrier;

  //NOTE: So é permitido alterar MOTORISTA do tipo:FOB usuários diferentes de Motorista (driver).
  const isRenderDriveFOB = isFOBSelected && !onlyDriver;

  const [listPlates, setListPlates] = useState<string[]>([]);

  const [loadEditDriver, setLoadEditDriver] = useState(false);
  const [selectedDrivers, setSelectedDrivers] = useState<Option | undefined>(
    undefined
  );

  const [selectedDriverName, setSelectedDriverName] = useState<string>("");
  const [selectedPlate, setSelectedPlate] = useState<string>("");
  const [loadingPlates, setLoadingPlates] = useState(false);
  const [errorPlate, setErrorPlate] = useState<string | null>(null);
  const [errorDriver, setErrorDriver] = useState<string | null>(null);

  const isDriverValid =
    (selectedDriverName.length > 0 || selectedDrivers) && errorDriver === null;

  const isPlateValid = validatePlate(selectedPlate) && errorPlate === null;

  //NOTE: Irá validar o campo de "motorista" apenas se o usuário for diferente de Motorista.
  //NOTE: Irá validar o campo de "placa" apenas se a FLAG tiver ativa.
  const isDisabledButtonFinished =
    (!isDriverValid && !onlyDriver) || (!isPlateValid && isRenderPlateFlag);

  const handlePlateChange = (e) => {
    const plate = maskVehiclePlateMercoSul(e.target.value);
    setSelectedPlate(plate);
  };

  const optionsDrivers = (drivers?: IDrivers[]) => {
    return Array.isArray(drivers)
      ? drivers?.map((driver, index) => {
          return {
            id: driver?.id ?? `${index}`,
            name: driver?.name ?? "Nao definido",
          };
        }) ?? []
      : [];
  };

  const handleFetchPlates = async (input: string) => {
    if (!input.trim()) {
      setListPlates([]);
      return;
    }

    setLoadingPlates(true);

    try {
      const response = await getVehicle("", input);
      const plates = response.map(({ plate }) => plate);

      setListPlates(plates);
    } catch (error) {
      snackbar({
        message: "Erro ao buscar placas. Por favor, tente novamente.",
        variant: "error",
      });
    } finally {
      setLoadingPlates(false);
    }
  };

  const handleInputChange = async (_: any, newInputValue: string) => {
    if (newInputValue.length < 8) {
      await handleFetchPlates(normalizePlate(newInputValue));
    }
  };

  const handleOnClickEditDriver = async (selectedDrivers?: Option) => {
    if (!selectedDrivers) return;
    setLoadEditDriver(true);

    const orderId = arrayToStringJoin(schedule?.salesOrder, KEY.ORDER);

    const payloadBase = {
      orderId,
      ...(isRenderPlateFlag && { plate: normalizePlate(selectedPlate) }),
    };

    const payload = {
      ...payloadBase,
      ...(hasCarrierAndHasCIF && { driverName: selectedDriverName }),
      ...(isRenderDriveFOB && { driverId: selectedDrivers.id }),
    };

    await handleRoadLoadMethod.fetchCreateSchedule(
      payload,
      schedule?.scheduleGroupID
    );
    setLoadEditDriver(false);
  };

  const handlePlateSelection = (_: any, newValue: string | null) => {
    const plate = newValue ? newValue.replace(/-/g, "") : "";
    setSelectedPlate(maskVehiclePlateMercoSul(plate));
    setLoadingPlates(false);
  };

  const renderDriverDisabled = () => (
    <>
      <TextFieldCustom
        disabled={true}
        name="driver-disabled"
        type="text"
        variant="outlined"
        label="Motorista"
        onChange={() => {}}
        value={selectedDriverName}
      />
      <FormHelperText error sx={{ fontSize: "1.5rem" }}>
        *Você não possui permissão para alterar o motorista
      </FormHelperText>
    </>
  );

  const renderFOBSelection = () => (
    <Grid container spacing={2} xs={12}>
      <Grid item xs={12} md={6}>
        {onlyDriver ? (
          renderDriverDisabled()
        ) : (
          <SelectAutocomplete
            label="Motorista"
            value={selectedDrivers}
            options={optionsDrivers(roadLoadFilterOptions?.drivers)}
            getOptionLabel={(option) => option.name}
            onChange={(value) => {
              if (value !== null && !Array.isArray(value)) {
                setSelectedDrivers(value);
              }
            }}
            showError={!!errorDriver}
            showErrorText={errorDriver || ""}
            noOptionsText="Nenhum motorista encontrado"
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {isRenderPlateFlag && (
          <Autocomplete
            options={listPlates}
            noOptionsText="Placa não encontrada"
            loadingText="Carregando opções"
            value={selectedPlate}
            onInputChange={handleInputChange}
            onChange={handlePlateSelection}
            loading={loadingPlates}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Placa"
                variant="outlined"
                error={!!errorPlate}
                helperText={errorPlate || ""}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingPlates && <CircularProgress size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      </Grid>
    </Grid>
  );

  const renderCIFSelection = () => (
    <Grid container spacing={2} xs={12}>
      <Grid item xs={12} md={6}>
        <TextFieldCustom
          name="driver"
          type="text"
          variant="outlined"
          label="Motorista"
          onChange={(e) => setSelectedDriverName(e.target.value)}
          value={selectedDriverName}
          error={!selectedDriverName}
          helperText={!selectedDriverName ? "Informe um motorista" : ""}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {isRenderPlateFlag && (
          <TextFieldCustom
            name="plate"
            type="text"
            variant="outlined"
            label="Placa"
            onChange={handlePlateChange}
            inputProps={{ maxLength: 8 }}
            value={selectedPlate}
            error={!!errorPlate}
            helperText={errorPlate}
          />
        )}
      </Grid>
    </Grid>
  );

  const validateAndSetErrorDriver = (
    driverName: string | undefined,
    selectedDriver: Option | undefined,
    drivers: IDrivers[] | undefined
  ) => {
    const isDriverListFOBEmpty = (drivers ?? []).length === 0;

    if (isDriverListFOBEmpty && isFOBSelected) {
      setErrorDriver(
        `O motorista ${driverName} não está disponível para esse CNPJ`
      );
    } else if (!driverName || !selectedDriver) {
      setErrorDriver("Informe um motorista");
    } else {
      setErrorDriver(null);
    }
  };

  const validateAndSetErrorPlate = (
    currentPlate: string | undefined,
    selectedPlate: string
  ) => {
    if (selectedPlate && !validatePlate(selectedPlate)) {
      setErrorPlate(
        "Placa inválida. Use o formato correto (ABC-1234) ou (ABC-1D34)"
      );
    } else if (!currentPlate && !selectedPlate) {
      setErrorPlate("Informe uma placa");
    } else {
      setErrorPlate(null);
    }
  };

  useEffect(() => {
    handleFetchDriverByCNPJ(salesOrderCNPJ);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesOrderCNPJ]);

  useEffect(() => {
    setSelectedDriverName(schedule?.truckDriver ?? "Não informado");
    setSelectedPlate(schedule?.plate ?? "");
    setSelectedDrivers({
      id: schedule?.truckDriverId ?? "",
      name: schedule?.truckDriver ?? "",
    });
  }, [schedule?.plate, schedule?.truckDriver, schedule?.truckDriverId]);

  useEffect(() => {
    const driverName = schedule?.truckDriver;
    const listDriver = roadLoadFilterOptions?.drivers;

    validateAndSetErrorDriver(driverName, selectedDrivers, listDriver);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule?.truckDriver, selectedDrivers, roadLoadFilterOptions?.drivers]);

  useEffect(() => {
    const currentPlate = schedule?.plate;
    validateAndSetErrorPlate(currentPlate, selectedPlate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule?.plate, selectedPlate]);

  return (
    <Modal open={open} onClose={onClose} hiddenButton>
      {({ handleClose }) => {
        return (
          <Box>
            <Grid container gap={5} justifyContent={"center"}>
              <Grid item xs={12}>
                <TypographyTitle>Alterar Motorista/Placa</TypographyTitle>
              </Grid>

              <Grid item xs={12}>
                <Grid container gap={3}>
                  {isFOBSelected && renderFOBSelection()}

                  {hasCarrierAndHasCIF && renderCIFSelection()}

                  <Grid item xs={12}>
                    <TextFieldCustom
                      name={"Ref"}
                      type={"text"}
                      variant={"outlined"}
                      label={"Numero do Agendamento"}
                      value={schedule?.Ref ?? "Nao definido"}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      name={"scheduleDate"}
                      type={"text"}
                      variant={"outlined"}
                      label={"Data de Entrega"}
                      value={
                        formatDateAndHourPTBR(
                          schedule?.scheduleDate,
                          schedule?.timezone
                        ) ?? "Nao definido"
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      name={"filial"}
                      type={"text"}
                      variant={"outlined"}
                      label={"Nome da Filial"}
                      value={`${
                        schedule
                          ? `${schedule?.filialName}-${schedule?.filial}`
                          : '"Nao definido'
                      }`}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      name={"filial"}
                      type={"text"}
                      variant={"outlined"}
                      label={"Tipo do Frete"}
                      value={valueFreight}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent={"right"} gap={3}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      disabled={loadEditDriver}
                      onClick={() => handleClose()}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={isDisabledButtonFinished}
                      loading={loadEditDriver}
                      onClick={async () => {
                        await handleOnClickEditDriver(selectedDrivers);
                        handleClose();
                      }}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Modal>
  );
};

export default ModalEditDriver;
