import { Divider, Grid, Hidden, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Button from "@components/Button";
import {
  formatDateSchedule,
  formatTimeSchedule,
  formatToAmountLiters,
} from "@utils/index";

interface CustomTimeInputScheduleProps {
  onClickCalendarClose?: () => void;
  onClickCalendarSend?: () => Promise<void>;
  onChangeResetButton?: () => void;
  selected?: Date | null;
  loading?: boolean;
  totalAmountLiters?: string;
}

const CustomTimeInputSchedule: React.FC<CustomTimeInputScheduleProps> = ({
  onClickCalendarClose,
  onClickCalendarSend,
  onChangeResetButton,
  selected,
  loading,
  totalAmountLiters,
}) => {
  const [load, setLoad] = useState<boolean>(loading ?? false);
  const [selectedTime, setSelectedTime] = useState<Date | undefined | null>(
    selected ?? undefined
  );

  const fullDateFormatted = formatDateSchedule(selectedTime);
  const timeFormatted = formatTimeSchedule(selectedTime);

  useEffect(() => {
    if (loading !== undefined) {
      setLoad(loading);
    }

    if (selected !== null || selectedTime !== selected) {
      setSelectedTime(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, selected]);

  const handleSchedule = async () => {
    try {
      setLoad(true);
      onClickCalendarSend && (await onClickCalendarSend());
    } catch (error) {
      throw error;
    } finally {
      setLoad(false);
    }
  };

  const CustomGridItem = ({ children }) => {
    return (
      <Grid
        item
        xs={5}
        sx={{
          fontWeight: 700,
          backgroundColor: "#D2D3D6",
          borderRadius: 1,
          padding: 0.4,
        }}
      >
        <Typography variant="h6">{children}</Typography>
      </Grid>
    );
  };

  return (
    <Grid
      container
      className="CustomTimeInputSchedule-container"
      sx={{
        display: "block",
        textAlign: "justify",
        gap: { xs: 1, sm: 1.5 },
        justifyContent: { xs: "space-around", sm: "flex-start" },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} gap={2}>
          <Grid item>
            <Typography
              variant={"body2"}
              sx={{ fontSize: { xs: 11, md: 12, lg: 13, xl: 14 } }}
            >
              Dia e horário selecionado
            </Typography>
          </Grid>

          <Grid item mt={2}>
            <Grid
              container
              gap={1.5}
              sx={{ textAlign: "center", opacity: 0.5 }}
            >
              <CustomGridItem>{fullDateFormatted}</CustomGridItem>

              <CustomGridItem>{timeFormatted}</CustomGridItem>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          {totalAmountLiters && (
            <>
              <Grid item>
                <Typography
                  variant={"body2"}
                  sx={{ fontSize: { xs: 11, md: 12, lg: 13, xl: 14 } }}
                >
                  Volume Total (L)
                </Typography>
              </Grid>
              <Grid item mt={2}>
                <Grid
                  container
                  gap={1.5}
                  sx={{ textAlign: "center", opacity: 0.5 }}
                >
                  <CustomGridItem>
                    {formatToAmountLiters(totalAmountLiters)}
                  </CustomGridItem>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} mt={2}>
        <Typography
          variant="body2"
          sx={{ fontSize: { xs: 10, md: 10, lg: 11, xl: 12 } }}
        >
          *Há uma tolerância de 15 minutos
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ p: 1 }}>
        <Divider />
      </Grid>

      <Grid item xs={11.5} sx={{ backgroundColor: "#fff", pb: 2 }}>
        <Grid
          container
          sx={{
            justifyContent: { xs: "center", md: "space-between" },
            gap: { xs: 3, md: 1 },
          }}
        >
          <Hidden smDown>
            <Grid item>
              <Button disabled={load} onClick={onChangeResetButton}>
                Limpar
              </Button>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container gap={1.5}>
              <Grid item>
                <Button variant="outlined" onClick={onClickCalendarClose}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  loading={load}
                  onClick={handleSchedule}
                >
                  Agendar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomTimeInputSchedule;
