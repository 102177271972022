import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { formatCNPJOrCPF } from "../../../../utils";
import IFormEditUser from "./IFormEditUser";
import { CactusIcon, CheckListIcon } from "@components/Icons";
import { DeleteForeverOutlined, KeyboardArrowDown } from "@material-ui/icons";
import useAddCNPJ from "@pages/Admin/hooks/useAddCNPJ";
import PageNotFound from "@components/PageNotFound/PageNotFound";
import ProfileType from "@pages/Admin/AddUser/FormAddUser/ENUM_ADD_USER";
import { profileOptions } from "@pages/Admin/AddUser/FormAddUser/Form";
import useCNPJsFromBpId from "@hooks/useCNPJsFromBpId";
import { useHistory } from "react-router-dom";
import ROUTES from "@config/routes";
import { useFlags } from "flagsmith/react";

interface Props {
  loading: boolean;
}

const FormEditUser: React.FC<Props> = ({ loading }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { touched, values, errors, setFieldValue } = useFormikContext<
    IFormEditUser
  >();

  const { bpIdData, isLoading, fetchCNPJsFromBPID } = useCNPJsFromBpId();

  const handleAddCNPJ = useAddCNPJ(values, setFieldValue);

  const flag = useFlags(["is_carrier_type_user"]);
  const isUserTypeCarrierFlagEnabled = flag.is_carrier_type_user.enabled;

  const featureFlagBPIDDriver = useFlags(["new_driver_bpid"]).new_driver_bpid
    .enabled;

  const existsCarrier = profileOptions.some(
    (option) => option.value === ProfileType.CARRIER
  );

  if (isUserTypeCarrierFlagEnabled && !existsCarrier) {
    profileOptions.push({
      value: ProfileType.CARRIER,
      label: "Transportadora",
    });
  }

  const isCarrierProfile = values.profile === ProfileType.CARRIER;
  const isDriverProfile = values.profile === ProfileType.DRIVER;
  const isGroupCNPJEmpty = !values?.attributes?.CNPJ?.length;
  const isDriverDBPIValid = isDriverProfile && featureFlagBPIDDriver;

  const hasErrorProfile = Boolean(errors.profile || !values.profile);
  const hasErrorFirstName = Boolean(errors.firstName || !values.firstName);
  const hasErrorLastName = Boolean(errors.lastName || !values.lastName);
  const hasErrorEmail = Boolean(errors.email || !values.email);
  const hasErrorBPId = Boolean(
    errors.attributes?.BPID || !values.attributes?.BPID
  );
  const hasErrorINTERNAL_NAME = Boolean(
    errors.attributes?.INTERNAL_NAME || !values.attributes?.INTERNAL_NAME
  );

  const validationErrors = [
    hasErrorProfile,
    hasErrorFirstName,
    hasErrorLastName,
    hasErrorEmail,
    isGroupCNPJEmpty,
  ];

  // NOTE:Adicionar validação de BPId se for transportadora
  if (isCarrierProfile) {
    validationErrors.push(hasErrorBPId);
    validationErrors.push(hasErrorINTERNAL_NAME);
  }

  // NOTE:Adicionar validação de BPId se for motorista
  if (isDriverDBPIValid) {
    validationErrors.push(hasErrorBPId);
  }

  const isInvalid = validationErrors.some(Boolean);

  const clearGroupCNPJs = () => {
    setFieldValue("attributes.INTERNAL_NAME", "");
    setFieldValue("attributes.CNPJ", []);
  };

  const clearBpIdAndName = () => {
    setFieldValue("attributes.BPID", "");
    setFieldValue("attributes.INTERNAL_NAME", "");
  };

  const handleGoBack = () => history.push(ROUTES.ADMIN_ROUTES.LIST_USERS);

  const handleAddCNPJsFromBpId = () => {
    if (!bpIdData) return;

    const newCNPJ = bpIdData.companies.map((item) => ({
      CNPJ: item.cnpj,
      companyName: item.description,
    }));

    const newCNPJCarrierName = bpIdData.carrierName;
    setFieldValue("attributes.INTERNAL_NAME", newCNPJCarrierName);
    setFieldValue("attributes.CNPJ", newCNPJ);
  };

  const handleTextFieldBPID = (label: string) => (
    <Field
      name="attributes.BPID"
      label={label}
      as={TextField}
      variant="outlined"
      placeholder="BP do motorista"
      fullWidth
      error={touched.attributes?.BPID && !!errors.attributes?.BPID}
      helperText={touched.attributes?.BPID && errors.attributes?.BPID}
    />
  );

  useEffect(() => {
    const isDataCNPJsFromBpId =
      bpIdData?.companies && bpIdData?.companies?.length > 0;

    if (isDataCNPJsFromBpId) {
      handleAddCNPJsFromBpId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bpIdData]);

  return (
    <Grid className={classes.gridContainer} container spacing={3}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={12}
      >
        <Typography className={classes.titleDataUser}>
          Dados do usuário
        </Typography>
        <CheckListIcon style={{ height: "2rem" }} />
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={3}>
          <Grid
            xs={12}
            {...(isDriverDBPIValid && { md: 6, lg: 6 })}
            item
            style={{ marginTop: "1.5rem" }}
          >
            <FormControl fullWidth variant="outlined">
              <InputLabel
                htmlFor="profile"
                className={classes.inputLabel}
                style={{ fontWeight: !!touched.profile ? "bold" : "none" }}
              >
                Tipo de usuário
              </InputLabel>

              <Field
                name="profile"
                label="Perfil"
                as={Select}
                fullWidth
                error={touched.profile && !!errors.profile}
                helperText={touched.profile && errors.profile}
                IconComponent={KeyboardArrowDown}
                className={classes.Field}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const selectedValue = event.target.value;
                  setFieldValue("profile", selectedValue);
                  clearBpIdAndName();
                }}
              >
                {profileOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              {touched.profile && errors.profile && (
                <Typography variant="caption" color="error">
                  {errors.profile}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {isDriverDBPIValid && (
            <Grid xs={12} md={6} lg={6} item style={{ marginTop: "1.5rem" }}>
              {handleTextFieldBPID("BP do motorista")}
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Field
              label="Primeiro nome"
              name="firstName"
              as={TextField}
              variant="outlined"
              placeholder="Primeiro nome"
              fullWidth
              error={touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Field
              label="Sobrenome"
              className={classes.lastName}
              name="lastName"
              as={TextField}
              variant="outlined"
              placeholder="Sobrenome"
              fullWidth
              error={touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              label="E-mail"
              name="email"
              as={TextField}
              variant="outlined"
              placeholder="E-mail"
              fullWidth
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
          </Grid>

          {isCarrierProfile && (
            <Grid
              xs={12}
              item
              style={{
                border: `1px dashed ${theme.palette.shadesOfDark.light}`,
                borderRadius: "5px",
              }}
            >
              <Grid xs={12} item>
                {handleTextFieldBPID("BP da transportadora")}
              </Grid>
              <Box height={"3rem"} />
              <Grid xs={12} item>
                <Field
                  name="attributes.INTERNAL_NAME"
                  label={isLoading ? "Pesquisando..." : "Razão Social"}
                  as={TextField}
                  variant="outlined"
                  disabled={true}
                  fullWidth
                  error={
                    touched.attributes?.INTERNAL_NAME &&
                    !!errors.attributes?.INTERNAL_NAME
                  }
                  helperText={
                    touched.attributes?.INTERNAL_NAME &&
                    errors.attributes?.INTERNAL_NAME
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  paddingTop: 0,
                }}
              >
                <Button
                  onClick={() =>
                    fetchCNPJsFromBPID(values.attributes?.BPID, clearGroupCNPJs)
                  }
                  disabled={!values.attributes?.BPID || isLoading}
                >
                  <Tooltip
                    title={
                      !isGroupCNPJEmpty
                        ? "Ao pesquisar novamente, os CNPJs listadosatualmente na tabela serão excluidos"
                        : ""
                    }
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color:
                          !values.attributes?.BPID || isLoading
                            ? theme.palette.shadesOfDark.medium
                            : theme.palette.primary.main,
                        textTransform: "none",
                        fontSize: "1.5rem",
                      }}
                    >
                      {isLoading ? "Pesquisando..." : "Pesquisar CNPJs"}
                    </Typography>
                  </Tooltip>
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <TextField
              label="CNPJ ou CPF"
              name="CNPJ"
              variant="outlined"
              placeholder="CNPJ ou CPF"
              fullWidth
              value={values.CNPJ}
              disabled={isLoading}
              onChange={({ target }) => {
                const notMasked = target.value.replace(/\D/g, "");

                if (notMasked.length <= 14) {
                  const masked = formatCNPJOrCPF(notMasked);
                  setFieldValue("CNPJ", masked);
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Field
              label="Razão Social"
              name="companyName"
              as={TextField}
              disabled={isLoading}
              variant="outlined"
              placeholder="Razão Social"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "2rem",
            }}
          >
            <Button onClick={handleAddCNPJ} disabled={isLoading}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: isLoading
                    ? theme.palette.shadesOfDark.medium
                    : theme.palette.primary.main,
                  textTransform: "none",
                  fontSize: "1.5rem",
                }}
              >
                + Adicionar
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container style={{ marginTop: "1.5rem" }}>
          <Grid item xs={12} className={classes.tableCompany}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">CNPJ ou CPF</TableCell>
                  <TableCell align="center">Razão Social</TableCell>
                  <TableCell align="center">Ações</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values?.attributes?.CNPJ?.map((dataCNPJ, idx) => {
                  return (
                    <TableRow key={dataCNPJ.CNPJ}>
                      <TableCell align="center">
                        {formatCNPJOrCPF(dataCNPJ.CNPJ)}
                      </TableCell>
                      <TableCell align="center">
                        {dataCNPJ.companyName}
                      </TableCell>
                      <TableCell>
                        <Grid container justify="center">
                          <IconButton
                            onClick={() => {
                              const newCNPJ = [...values.attributes.CNPJ];
                              newCNPJ.splice(idx, 1);
                              setFieldValue("attributes.CNPJ", newCNPJ);
                            }}
                          >
                            <Tooltip title="Remover">
                              <DeleteForeverOutlined />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {isGroupCNPJEmpty && (
              <Box p={2}>
                <PageNotFound
                  title={
                    isLoading
                      ? "Pesquisando..."
                      : "Não há CPF ou CNPJ adicionados"
                  }
                  subtitle={
                    isCarrierProfile
                      ? "Informe o BP da Transportadora para preenchimento automático dos CNPJs"
                      : "Informe um CPF ou CNPJ para incluir no cadastro"
                  }
                  icon={<CactusIcon />}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          className={classes.button}
          onClick={handleGoBack}
          variant="text"
          color="primary"
        >
          Cancelar
        </Button>
        <Box width={"2rem"} />

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isInvalid}
          endIcon={
            loading && (
              <CircularProgress
                color="secondary"
                style={{ width: "2rem", height: "2rem" }}
              />
            )
          }
        >
          {loading ? "Enviando ..." : "Enviar"}
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonAdd: {
      color: green[600],
    },
    titleDataUser: {
      fontFamily: "DM Sans",
      fontWeight: 700,
      fontSize: "20px",
      color: "#3E3D3D",
    },
    gridContainer: {
      backgroundColor: "#fff",
      padding: "3rem",
      margin: "0 0 10px 3rem",
      borderRadius: "4px",
      width: "95%",
    },
    button: {
      textTransform: "capitalize",
      boxShadow: "none",
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.5rem",
    },
    secondaryItem: {
      fontSize: "1.3rem",
      color: "#CCCCCC",
    },
    lastName: {
      marginLeft: ".7rem",

      "@media screen and (max-width: 768px)": {
        marginLeft: 0,
      },
    },
    tableCompany: {
      border: "1px solid #72655133",
      borderRadius: "4px",
    },
    inputLabel: {
      position: "absolute",
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "1.6rem",
      backgroundColor: "#fff",
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
    Field: {
      borderRadius: "8px",
    },
  })
);

export default FormEditUser;
