import React from "react";
import Button from "@components/Button";
import Modal from "@components/Modal";
import { useHistory } from "react-router-dom";
import iconTableLastOrderFT from "../../../../../assets/iconTableLastOrderFT.svg";
import ROUTES from "@config/routes";
import useStyles from "./styles";
import { TypographyCaption, TypographyTitle } from "../../resource/typography";
import { Box, Grid } from "@mui/material";

interface ConfirmationModalProps {
  open: boolean;
  programNumber: string;
  onClose: () => void;
}

const ModalFinalCreateTransport: React.FC<ConfirmationModalProps> = ({
  open,
  programNumber,
  onClose,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const handleConfirm = () => {
    onClose();
    history.push(ROUTES.USER_ROUTES.ROADLOAD);
  };

  return (
    <Modal open={open} onCloseCallback={onClose} hiddenButton>
      <Box
        className={classes.modalContainer}
        sx={{ width: { xs: "260", sm: "320px", lg: "350px" } }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              src={iconTableLastOrderFT}
              alt="Icone confirmação"
              className={classes.icon}
            />
          </Grid>

          <Grid item xs={12}>
            <TypographyTitle sx={{ fontSize: { xs: 24, lg: 26 } }}>
              Programação
              <span className={classes.programNumber}>{programNumber}</span>
              criada com sucesso.
            </TypographyTitle>
          </Grid>

          <Grid item sx={{ px: { xs: 3, lg: 1 } }}>
            <TypographyCaption sx={{ fontSize: { xs: 14, lg: 15 } }}>
              Informe o número do agendamento ao faturista para prosseguir com o
              carregamento.
            </TypographyCaption>
          </Grid>
          <Grid container sx={{ justifyContent: "center", pt: "2rem" }}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleConfirm}
              >
                Ok, entendi
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalFinalCreateTransport;
