import React, { useEffect, useState } from "react";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import { ArrowDownSelectIcon } from "@components/Icons";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface Option {
  id: string;
  name: string;
}

interface Props {
  label: string;
  options: Option[];
  value: Option | Option[] | null | undefined;
  onChange: (value?: Option[] | Option | null) => void;
  reset?: () => void;
  multiple?: boolean;
  getOptionLabel: (option) => string;
  showError?: boolean | undefined;
  showErrorText?: React.ReactNode;
  noOptionsText?: string;
  disabled?: boolean;
}

const SelectAutocomplete: React.FC<Props> = (props) => {
  const {
    options,
    onChange,
    label,
    multiple,
    showError,
    showErrorText,
    noOptionsText,
    disabled = false,
  } = props;

  const initialValue = multiple ? ([] as Option[]) : ({} as Option); // NOTE: Define o valor inicial com base no prop 'multiple'
  const [value, setValue] = useState<Option[] | Option | undefined | null>(
    initialValue
  );

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (value !== props.value) {
      if (props.value === null || props.value === undefined) {
        return setValue(multiple ? [] : null);
      }
      return setValue(props.value);
    }
  }, [props.value, value, setValue, multiple]);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleCleanTags = () => {
    setValue([]);
    onChange([]);
  };

  const isOptionEqualToValue = (option, value): boolean => {
    if (Object.keys(value).length === 0) return true;

    if (!option || !value || Array.isArray(option) || Array.isArray(value)) {
      return false;
    }

    return option?.id === value?.id || option?.name === value?.name;
  };

  const getOptionLabelCustom = (option) => {
    if (!option || Object.keys(option).length === 0) return "";
    if (props.getOptionLabel) {
      return props.getOptionLabel(option);
    }
    return `${option.name} - ${option.id}`;
  };

  return (
    <Autocomplete
      disabled={disabled}
      open={menuOpen}
      noOptionsText={noOptionsText}
      onClose={handleMenuClose}
      onOpen={handleMenuOpen}
      isOptionEqualToValue={isOptionEqualToValue}
      multiple={multiple}
      limitTags={2}
      options={options}
      value={value}
      onChange={(event, newValue) => {
        if (newValue === null || newValue === undefined) {
          setValue(multiple ? [] : null);
          onChange(multiple ? [] : null);
          return;
        }

        if (!Array.isArray(newValue)) {
          setValue(newValue);
          onChange(newValue);
          return;
        }

        if (multiple && Array.isArray(newValue)) {
          setValue(newValue as Option[]);
          onChange(newValue as Option[]);
          return;
        }
      }}
      getOptionLabel={getOptionLabelCustom} // Note: Customiza a apresentacao das opcoes - sem ele nao funciona
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ whiteSpace: "nowrap" }}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            style={{
              color: selected ? "red" : "inherit",
            }}
          />
          <Typography variant={"body1"} sx={{ fontSize: "1.2rem" }}>
            {!Array.isArray(option) && `${option?.name}`}
          </Typography>
        </li>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            disabled={disabled}
            className="textField-custom"
            label={label}
            variant="outlined"
            error={showError}
            helperText={showErrorText}
            sx={{
              "& label,input": {
                fontFamily: "montserrat",
                fontSize: 14,
              },
              "& .MuiOutlinedInput-root": {
                border: "0px solid red",
                overflowY: "auto",
                maxHeight: "130px",
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: !disabled && (
                <InputAdornment
                  className="custom-input-Adornment"
                  position="end"
                  sx={{
                    position: "absolute",
                    right: 28,
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {multiple && Array.isArray(value) && value?.length > 0 && (
                    <IconButton
                      aria-label="toggle menu select clean"
                      onClick={handleCleanTags}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="toggle menu select"
                    onClick={handleMenu}
                    edge="end"
                  >
                    {menuOpen && !disabled ? (
                      <ArrowDownSelectIcon
                        sx={{ transform: "rotate(180deg)" }}
                      />
                    ) : (
                      <ArrowDownSelectIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      }}
      ChipProps={{
        sx: {
          "& .MuiChip-label": {
            fontFamily: "montserrat",
            fontSize: "1.2rem",
          },
          "&.MuiChip-root": {
            border: "0px solid red",
            maxWidth: "210px",
          },
        },
      }}
    />
  );
};

export default SelectAutocomplete;
