import React from "react";

import {
  BlockFinancialIcon,
  RoadLoadRedIcon,
  ClockIcon,
  CheckIcon,
} from "@components/Icons";

import { ISalesOrder, IScheduledLoad } from "../../model";
import { KEY } from "@utils/enum";

export const tableHeaderScheduledLoadOrders: {
  key: keyof ISalesOrder;
  label: string;
  align?: string;
  width?: string;
}[] = [
  {
    key: KEY.ORDER as keyof ISalesOrder,
    label: "Ordem de Venda",
  },
  {
    key: KEY.CNPJ as keyof ISalesOrder,
    label: "CNPJ/CPF",
  },
  {
    key: KEY.CUSTOMER as keyof ISalesOrder,
    label: "Cliente",
  },
  {
    key: KEY.PRODUCT as keyof ISalesOrder,
    label: "Produto",
  },
  {
    key: KEY.QUANTITY as keyof ISalesOrder,
    label: "Quantidade",
    align: "right",
  },
  {
    key: KEY.DELIVERYDATE as keyof ISalesOrder,
    label: "Data de Entrega",
    align: "center",
  },
  {
    key: KEY.COMPARTIMENTS as keyof ISalesOrder,
    label: "Compartimentos",
    align: "center",
  },
  {
    key: KEY.STATUS as keyof ISalesOrder,
    label: "Status",
    align: "center",
  },
];

export const tableHeaderUnscheduled = [
  {
    key: KEY.ICON,
    label: () => <ClockIcon style={{ width: 40, height: 40 }} />,
  },
  {
    key: KEY.CUSTOMER,
    label: "Cliente",
  },
  {
    key: KEY.CNPJ,
    label: "CNPJ/CPF",
  },
  {
    key: KEY.FILIALNAME,
    label: "Filial",
  },
  {
    key: KEY.PRODUCT,
    label: "Produto",
  },
  {
    key: KEY.QUANTITY,
    label: "Quantidade",
    align: "right",
  },
  {
    key: KEY.ORDER,
    label: "Ordem de venda",
    align: "right",
  },
  {
    key: KEY.FREIGHT,
    label: "Frete",
    align: "right",
  },
  {
    key: KEY.RELEASEDATE,
    label: "Data de entrega",
    align: "center",
  },
];

/**
 * Retorna os headers para a tabela de agendados.
 * A coluna de "Status" (KEY.STATUSPROGRAMATION) será incluída somente se a feature flag estiver ativa.
 */
export const getTableHeaderScheduled = (
  featureFlagProgrammingTransport: boolean
): {
  key: keyof IScheduledLoad;
  label: string | (() => JSX.Element);
  align?: string;
  width?: string;
}[] => {
  const headers = [
    {
      key: KEY.ICON as keyof IScheduledLoad,
      label: () => <RoadLoadRedIcon style={{ width: 40, height: 40 }} />,
    },
    {
      key: KEY.REF as keyof IScheduledLoad,
      label: "N° Agendamento",
      width: "fit-content",
      align: "center",
    },
    {
      key: KEY.TOTALAMOUNTLITERS as keyof IScheduledLoad,
      label: "Volume Total (L)",
      align: "right",
      width: "fit-content",
    },
    {
      key: KEY.TRUCKDRIVER as keyof IScheduledLoad,
      label: "Motorista",
    },
    {
      key: KEY.PLATE as keyof IScheduledLoad,
      label: "Placa",
    },
    {
      key: KEY.FREIGHT as keyof IScheduledLoad,
      label: "Frete",
      align: "right",
    },
    {
      key: KEY.BPID as keyof IScheduledLoad,
      label: "Transportadora",
      align: "right",
    },
    {
      key: KEY.FILIALNAME as keyof IScheduledLoad,
      label: "Filial",
    },
    {
      key: KEY.SALESORDERQUANTITY as keyof IScheduledLoad,
      label: "Quant. Ordens de Venda",
      align: "center",
    },
    {
      key: KEY.SCHEDULEDATE as keyof IScheduledLoad,
      label: "Agendado para",
      align: "center",
    },
  ];

  if (featureFlagProgrammingTransport) {
    headers.push({
      key: KEY.STATUSPROGRAMATION as keyof IScheduledLoad,
      label: "Status",
      align: "center",
    });
  }

  return headers;
};

export const tableHeaderFinished: {
  key: keyof IScheduledLoad;
  label: string | (() => JSX.Element);
  align?: string;
  width?: string;
}[] = [
  {
    key: KEY.ICON as keyof IScheduledLoad,
    label: () => <CheckIcon style={{ width: 40, height: 40 }} />,
  },
  {
    key: KEY.REF as keyof IScheduledLoad,
    label: "N° Agendamento",
    width: "fit-content",
    align: "center",
  },
  {
    key: KEY.TOTALAMOUNTLITERS as keyof IScheduledLoad,
    label: "Volume Total (L)",
    align: "right",
    width: "fit-content",
  },
  {
    key: KEY.TRUCKDRIVER as keyof IScheduledLoad,
    label: "Motorista",
  },
  {
    key: KEY.PLATE as keyof IScheduledLoad,
    label: "Placa",
  },
  {
    key: KEY.FILIALNAME as keyof IScheduledLoad,
    label: "Filial",
  },
  {
    key: KEY.SALESORDERQUANTITY as keyof IScheduledLoad,
    label: "Quant. Ordens de Venda",
    align: "center",
  },
  {
    key: KEY.FREIGHT as keyof IScheduledLoad,
    label: "Frete",
    align: "right",
  },
  {
    key: KEY.SCHEDULEDATE as keyof IScheduledLoad,
    label: "Concluido",
    align: "left",
  },
];

export const tableHeaderCanceled: {
  key: keyof IScheduledLoad;
  label: string | (() => JSX.Element);
  align?: string;
  width?: string;
}[] = [
  {
    key: KEY.ICON as keyof IScheduledLoad,
    label: () => <RoadLoadRedIcon style={{ width: 40, height: 40 }} />,
  },
  {
    key: KEY.REF as keyof IScheduledLoad,
    label: "N° Agendamento",
    width: "fit-content",
    align: "center",
  },
  {
    key: KEY.TRUCKDRIVER as keyof IScheduledLoad,
    label: "Motorista",
  },
  {
    key: KEY.FILIALNAME as keyof IScheduledLoad,
    label: "Filial",
    align: "left",
  },
  {
    key: KEY.REASONCANCELLATION as keyof IScheduledLoad,
    label: "Motivo do Cancelamento",
    align: "center",
  },
  {
    key: KEY.TOTALAMOUNTLITERS as keyof IScheduledLoad,
    label: "Quant. Total em L.",
    align: "right",
    width: "fit-content",
  },
  {
    key: KEY.SALESORDERQUANTITY as keyof IScheduledLoad,
    label: "Quant. Ordens de Venda",
    align: "center",
  },
  {
    key: KEY.FREIGHT as keyof IScheduledLoad,
    label: "Frete",
    align: "right",
  },
  {
    key: KEY.SCHEDULEDATE as keyof IScheduledLoad,
    label: "Foi Agendado em",
    align: "center",
  },
];

export const tableHeaderBlocked = [
  {
    key: KEY.ICON,
    label: () => <BlockFinancialIcon style={{ width: 40, height: 40 }} />,
  },
  {
    key: KEY.CUSTOMER,
    label: "Cliente",
  },
  {
    key: KEY.CNPJ,
    label: "CNPJ/CPF",
  },
  {
    key: KEY.FILIALNAME,
    label: "Filial",
  },
  {
    key: KEY.PRODUCT,
    label: "Produto",
  },
  {
    key: KEY.QUANTITY,
    label: "Quantidade",
    align: "right",
  },
  {
    key: KEY.ORDER,
    label: "Ordem de venda",
    align: "right",
  },
  {
    key: KEY.FREIGHT,
    label: "Frete",
    align: "right",
  },
  {
    key: KEY.BLOCKEDDATE,
    label: "Data de entrega",
    align: "center",
  },
];
